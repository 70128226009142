import {AppRegistry} from 'react-native';
import './index.css';
import App from './App';
// // Use prebuilt version of RNVI in dist folder
// import Icon from 'react-native-vector-icons/dist/Feather';
// Generate required css
import iconFont from 'react-native-vector-icons/Fonts/Feather.ttf';
import regularFont from './assets/fonts/OpenSans-Regular.ttf';
import semiBoldFont from './assets/fonts/OpenSans-SemiBold.ttf';
import boldFont from './assets/fonts/OpenSans-Bold.ttf';
import extraBoldFont from './assets/fonts/OpenSans-ExtraBold.ttf';

const iconFontStyles = `
@font-face {
  src: url(${iconFont});
  font-family: Feather;
  font-display: swap;
}
@font-face {
  font-family: "OpenSans";
  src: url(${regularFont})
}
@font-face {
  font-family: "OpenSansSemiBold";
  src: url(${semiBoldFont})
}
@font-face {
  font-family: "OpenSansBold";
  src: url(${boldFont})
}
@font-face {
  font-family: "OpenSansExtraBold";
  src: url(${extraBoldFont})
}
`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });
